import { withPrefix } from "gatsby"
import React, { Component } from "react"
import { Col, Image, Row } from "react-bootstrap"
import Slider from "react-slick"

export default class Screens extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      currentSlide: null,
    }
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      currentSlide: 0,
    })
  }

  navToSlide(idx) {
    this.state.nav1.slickGoTo(idx)
    this.setState({ currentSlide: idx })
  }

  render() {
    return (
      <div>
        <Slider
          ref={slider => (this.slider1 = slider)}
          dots={false}
          arrows={true}
          slidesToShow={1}
          slidesToScroll={1}
          infinite
          className="d-none d-lg-block"
          beforeChange={(_, newIndex) => {
            this.setState({ currentSlide: newIndex })
          }}
          autoplay
          autoplaySpeed={10000}
        >
          <div className="">
            <Image
              src={withPrefix("/images/screenshots/screenshot1@2x.png")}
              alt=""
              fluid
            />
          </div>
          <div className="">
            <Image
              src={withPrefix("/images/screenshots/screenshot2@2x.png")}
              alt=""
              fluid
            />
          </div>
          <div className="">
            <Image
              src={withPrefix("/images/screenshots/screenshot3@2x.png")}
              alt=""
              fluid
            />
          </div>
        </Slider>
        <h2 className="text-center mt-3 mb-4 carousel-slider-header">
          How is InSTEP different from other online learning platforms?
        </h2>
        <Row>
          <Col
            lg={4}
            tabIndex="0"
            onClick={e => this.navToSlide(0)}
            className={this.state.currentSlide === 0 ? "active" : null}
            onKeyPress={e => (e.key === "Enter" ? this.navToSlide(0) : null)}
          >
            <div className="mx-0 mx-lg-2">
              <Image
                src={withPrefix("/images/screenshots/screenshot1@2x.png")}
                alt=""
                fluid
                className="mx-auto d-block d-lg-none"
              />
              <p className="about-platform pt-3">
                Personalize learning to meet your professional needs through{" "}
                <strong>
                  customized recommendations, collaborative spaces, and choice
                  of learning modules
                </strong>{" "}
                and <strong>microcredentials.</strong>
              </p>
            </div>
          </Col>
          <Col
            lg={4}
            tabIndex="0"
            onClick={e => this.navToSlide(1)}
            className={this.state.currentSlide === 1 ? "active" : null}
            onKeyPress={e => (e.key === "Enter" ? this.navToSlide(1) : null)}
          >
            <div className="mx-0 mx-lg-2">
              <Image
                src={withPrefix("/images/screenshots/screenshot2@2x.png")}
                alt=""
                fluid
                className="mx-auto d-block d-lg-none"
              />
              <p className="about-platform pt-3">
                Build your skills in{" "}
                <strong>
                  data investigations and innovative teaching approaches
                </strong>{" "}
                based on practices of data professionals and research on
                students’ learning with data.
              </p>
            </div>
          </Col>
          <Col
            lg={4}
            tabIndex="0"
            onClick={e => this.navToSlide(2)}
            className={this.state.currentSlide === 2 ? "active" : null}
            onKeyPress={e => (e.key === "Enter" ? this.navToSlide(2) : null)}
          >
            <div className="mx-0 mx-lg-2">
              <Image
                src={withPrefix("/images/screenshots/screenshot3@2x.png")}
                alt=""
                fluid
                className="mx-auto d-block d-lg-none"
              />
              <p className="about-platform pt-3">
                Expand your{" "}
                <strong>
                  professional collection of resources and technology
                </strong>{" "}
                vetted by experts in statistics and data science education.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}
